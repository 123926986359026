<template>
  <BaseButton
    nuxt
    :to="religionLink"
    elevation="0"
    class="religion-item"
    :class="{ '-wide': isWide }"
  >
    <div>
      <v-img
        :src="religionIco"
        :lazy-src="religionIco"
        contain
        max-width="49"
        max-height="48"
        alt="picture"
      />
    </div>
    <div>{{ religionName }}</div>
  </BaseButton>
</template>
<script>
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
export default {
  name: 'ReligionItem',
  components: { BaseButton },
  props: {
    religionLink: {
      type: Object,
      default: () => ({})
    },
    isWide: {
      type: Boolean,
      default: false
    },
    religionIco: {
      type: String,
      default: ''
    },
    religionName: {
      type: String,
      default: 'Religion'
    }
  }
};
</script>
