<template>
  <AboutView />
</template>

<script>
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('BookPreview.vue');

import AboutView from '@about';
import pageCommonMixin from '@/components/mixins/pageCommonMixin';
import AppStateEnum from '@/enums/AppStateEnum';
import PublicationsTypesEnum from '@shared/enums/PublicationsTypesEnum';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import ScrollingModsEnum from '@/enums/ScrollingModsEnum';
import BrandsEnum from '@shared/enums/BrandsEnum.mjs';
import { mapGetters } from 'vuex';

let initBookPreviewPromise = null;

async function initBookPreview(store, { slug, previewKey }) {
  try {
    logger.info('Start build book preview');
    store.dispatch('PublicationStore/destroyPublicationState');
    store.dispatch('OpenParameterStore/destroyOpenParams');
    store.commit('PublicationStore/setIsApplyAllParams', false);

    store.dispatch('PublicationStore/setBookPreviewScrollStrategy');

    const paraRange = store.getters['ContextStore/getPreviewRange'](previewKey);
    const parsedParams = { slug, paraRange, previewMode: true };

    await store.dispatch('LibraryStore/initStateByPublicationTypes', {
      publicationsTypes: [PublicationsTypesEnum.BOOK],
      forceRemote: false
    });

    const openParams = await store.dispatch(
      'OpenParameterStore/buildOpenParameters',
      {
        ...parsedParams
      }
    );
    if (!openParams) {
      return;
    }
    const { publicationId } = openParams;

    store.dispatch('ReadingSettingsStore/initDefaultPublicationSettings', {
      publicationId,
      scrollingMode: ScrollingModsEnum.EACH_LINE
    });
    const isOnline = store.getters['ContextStore/isOnline'];
    if (!isOnline) {
      return;
    }
    await store.dispatch('PublicationStore/readMeta', publicationId);
    store.commit('PublicationStore/setIsApplyAllParams', true);
  } catch (error) {
    logger.error(`Get error on init book preview error:${error}`);
  }
}

export default {
  name: 'AboutPage',
  components: {
    AboutView
  },

  mixins: [pageCommonMixin],
  async asyncData(context) {
    const { store } = context;
    if (!initBookPreviewPromise) {
      try {
        const previewConfig = store.getters['ContextStore/getPreviewConfig'];
        initBookPreviewPromise = initBookPreview(store, previewConfig);
        await initBookPreviewPromise;
      } catch (error) {
        logger.error(
          `Get error on load book preview in asyncData error: ${error}`
        );
      }
      initBookPreviewPromise = null;
    }
    const canonicalLinkSetting = store.getters[
      'ContextStore/getPageCanonicalLinkSetting'
    ](AppStateEnum.MANAGE_ABOUT);
    return { canonicalLinkSetting };
  },
  data() {
    return {
      htmlClassList: this.$store.getters[
        'ContextStore/getPlatformHtmlClassList'
      ]
    };
  },
  head() {
    const headSettings = this.getCommonHeadSettings();
    const bodyAttrs = headSettings.bodyAttrs;
    const commonClasses = this.getCommonPageClasses();
    const classes = [...commonClasses, ...this.bookStyleClasses];
    const brand = this.$store.getters['ContextStore/brand'];
    const isOcean = brand === BrandsEnum.OCEAN;
    const link = [];
    if (this.canonicalLinkSetting) {
      link.push(this.canonicalLinkSetting);
    }
    return {
      ...headSettings,
      link,
      title: isOcean
        ? this.$t('AboutPageOcean.pageTitle.label')
        : this.$t('AboutPageFfa.pageTitle.label'),
      htmlAttrs: {
        class: [
          ...this.htmlClassList,
          AppConstantsUtil.MANAGE_PUBLICATION_SCROLL_CLASS
        ]
      },
      bodyAttrs: {
        ...bodyAttrs,
        class: classes
      }
    };
  },
  computed: {
    ...mapGetters('UserStore', ['isAuthenticated']),
    slug() {
      return this.$store.getters['OpenParameterStore/getSlug'];
    },
    publicationId() {
      return this.$store.getters['OpenParameterStore/getPublicationId'];
    },
    bookStyleClasses() {
      const bookStyleClass = this.$store.getters[
        'PublicationStore/getPublicationStyleClassName'
      ](this.publicationId);

      return bookStyleClass;
    }
  },
  watch: {
    async isAuthenticated(val) {
      if (val) {
        await this.loadUsedData();
      }
    }
  },
  async created() {
    this.$store.dispatch('PublicationStore/transformToClientData');
    if (this.isAuthenticated) {
      await this.loadUsedData();
    }
  },
  destroyed() {
    const appState = this.$store.getters['ContextStore/appState'];
    if (
      appState !== AppStateEnum.PRESENT_PUBLICATION &&
      appState !== AppStateEnum.MANAGE_ABOUT
    ) {
      this.$store.dispatch('OpenParameterStore/destroyOpenParams');
    }
  },
  methods: {
    async loadUsedData() {
      const previewConfig = this.$store.getters[
        'ContextStore/getPreviewConfig'
      ];
      if (this.slug !== previewConfig.slug && !initBookPreviewPromise) {
        logger.info(`Start load local publication`);
        try {
          initBookPreviewPromise = initBookPreview(this.$store, previewConfig);
          await initBookPreviewPromise;
        } catch (error) {
          logger.error(
            `Get error on load book preview in loadUsedData error: ${error}`
          );
        }

        initBookPreviewPromise = null;
      }
    }
  }
};
</script>

<style lang="less"></style>
